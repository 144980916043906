import React from "react";
import RequestButton from "../RequestButton";

const ArthritisInfo = () => {
  return (
    <div className="w-full flex flex-col justify-center items-center bg-white">
      <div className="w-4/5 lg:w-1/2 flex flex-col justify-start items-start py-12">
        <p className="text-3xl font-thin text-gray-800 mb-6">Arthritis Q & A</p>

        <p className="text-xl text-gray-800 mb-2">What is arthritis?</p>
        <p className="text-md text-gray-600 mb-2">
          Arthritis is a widespread problem that causes chronic joint pain and
          stiffness. There are numerous types of arthritis, some of which are
          rare, while others affect millions of people every year, all over the
          United States.
        </p>
        <p className="text-md text-gray-600 mb-2">
          Osteoarthritis is the type of arthritis you’re most likely to get. It
          usually affects your joints around the age of 50 and worsens over
          time. Osteoarthritis is typically the result of years of wear-and-tear
          on your joints. It may set in earlier if you’ve injured your joints or
          put them under a great deal of stress.
        </p>
        <p className="text-md text-gray-600 mb-2">
          Osteoarthritis develops as the coating of slippery cartilage on the
          ends of your bones wears away. Without cartilage to help them slide,
          the bones that make up your joints start to rub together, creating
          friction.
        </p>
        <p className="text-md text-gray-600 mb-12">
          The friction leads to inflammation, which causes pain that makes your
          joints throb, swell, and stiffen.
        </p>

        <p className="text-xl text-gray-800 mb-2">
          What other types of arthritis are there?
        </p>
        <p className="text-md text-gray-600 mb-2">
          Of the 100 or so types of arthritis, the one that affects most people
          (except for osteoarthritis) is rheumatoid arthritis (RA). RA tends to
          affect younger people, as it has an entirely different cause.
        </p>
        <p className="text-md text-gray-600 mb-2">
          Rheumatoid arthritis is an autoimmune disorder, which means your body
          destroys the membranes in your joints, mistakenly treating the cells
          as though they were harmful invaders. This damages the joint’s
          surfaces and causes the typical arthritis symptoms of pain, swelling,
          and stiffness.
        </p>
        <p className="text-md text-gray-600 mb-12">
          Gout is a form of arthritis that develops when uric acid crystals
          build up in the joints. It typically affects your feet and toes and
          can cause agonizing bouts of pain. Other forms of arthritis develop
          because of underlying diseases, like lupus, psoriasis, or infection.
        </p>

        <p className="text-xl text-gray-800 mb-2">How is arthritis treated?</p>
        <p className="text-md text-gray-600 mb-2">
          There’s no cure as yet for arthritis in any of its forms. Therefore,
          the Sunstate Medical Associates team’s aim in treating arthritis is to
          reduce the pain and inflammation, improve your mobility, and prevent
          the condition from worsening.
        </p>
        <p className="text-md text-gray-600 mb-2">
          One of the most important ways of treating any form of arthritis is to
          make changes in your lifestyle to promote good health. For example:
        </p>
        <ul className="text-gray-600 text-md list-inside list-disc pl-4 mb-2">
          <li>Losing weight</li>
          <li>Eating healthy foods</li>
          <li>Exercising regularly</li>
          <li>Quitting smoking</li>
          <li>Reducing alcohol intake</li>
          <li>Managing stress</li>
          <li>Staying hydrated</li>
        </ul>
        <p className="text-md text-gray-600 mb-2">
          The Sunstate Medical Associates team can help you with all these
          changes, providing information and support and monitoring your health.
        </p>
        <p className="text-md text-gray-600 mb-2">
          If you need medication for arthritis, anti-inflammatories can help.
          Over-the-counter medicines like ibuprofen can relieve milder pain, but
          you might need steroid injections for more severe pain. You might also
          benefit from hyaluronic acid (HA) injections to lubricate your joints.
        </p>
        <p className="text-md text-gray-600 mb-2">
          There are also more specialized medications for certain forms of
          arthritis. For example, if you have rheumatoid arthritis, drugs called
          DMARDs (disease-modifying antirheumatic drugs) can be helpful.
        </p>
        <p className="text-md text-gray-600 mb-10">
          Find out more about managing arthritis by calling Sunstate Medical
          Associates today or book an appointment online.
        </p>
        <RequestButton />
      </div>
    </div>
  );
};

export default ArthritisInfo;
