import React from "react";
import Layout from "../../components/Layout";
import PageHeader from "../../components/PageHeader";
import Conditions from "../../components/Conditions";
import ServicesHeader from "../../components/ServicesHeader";
import ArthritisInfo from "../../components/services/ArthritisInfo";

const Arthritis = () => {
  return (
    <Layout>
      <PageHeader text="Arthritis" />
      <ServicesHeader>
        The chronic joint pain caused by arthritis can limit your activities. If
        you’re looking for a better method of managing arthritis, the
        board-certified physicians at Sunstate Medical Associates in Lake Mary,
        Florida, can help. They provide effective therapies and support your
        healthy lifestyle changes, making every day more comfortable. Find out
        how you can benefit from the expertise of the Sunstate Medical
        Associates team by calling their office today or book an appointment
        using the online form.
      </ServicesHeader>
      <ArthritisInfo />
      <Conditions />
    </Layout>
  );
};

export default Arthritis;
